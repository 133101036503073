import React, { useEffect, useState } from "react";
import Main from "../../pages/main";
import Header from "../header/header";
import Popup from "../popup/popup";
import "./app.css";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Account from "../../pages/account/account";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../pages/auth/auth";
import Forgot from "../../pages/auth/forgot";
import Confirm from "../../pages/auth/confirm";
import New from "../../pages/auth/new";
import Done from "../../pages/auth/done";
import ChangeName from "../popup/changePopups/changeName";
import ChangePassword from "../popup/changePopups/changePassword";
import { changePageWidth } from "../../store/toolkitSlice";
import Footer from "../footer/footer";
import { changeStateAuthorized, changeUserData } from "../../store/authSlice";
import { authApi } from "../api/authService";
import CreateCardPopup from "../popup/createCard/createCardPopup";
import NotFound from "../../pages/404/404";
import AddCategory from "../popup/addCategory";
import ChangeCategory from "../popup/changePopups/changeCategory";
import AllFilters from "../filters/allFilters/allFilters";
import Preloader from "../preloader/preloader";
import ApprovePopup from "../popup/changePopups/approvePopup";
import {
	changeStateOpenAllFiltersPopup,
	changeStateOpenPopup,
	changeStateOpenPopupActions,
	changeStateOpenPopupAdd,
	changeStateOpenPopupChangeName,
	changeStateOpenPopupChangeNameCategory,
	changeStateOpenPopupChangePassword,
	changeStateOpenPopupCreateCard,
	changeStateOpenPopupPostSuccess,
	changeStateOpenPopupReturn,
	changeStateOpenPopupReturnRequest,
} from "../../store/popupSlice";

function App() {
	const authorized = useSelector((state) => state.auth.authorized);
	const authStatus = useSelector((state) => state.auth.authStatus);
	const cardInfo = useSelector((state) => state.popup.cardInfo);
	const [pageWidth, setPageWidth] = useState(
		document.documentElement.scrollWidth
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	window.onresize = newPageSize;
	function newPageSize() {
		setPageWidth(document.documentElement.scrollWidth);
		dispatch(changePageWidth(pageWidth));
	}
	const [
		setToken,
		{ data: access, isLoading: isFetchingSetToken, error: authError },
	] = authApi.useVerifyTokenMutation();
	const [
		refreshToken,
		{
			data: newToken,
			isLoading: isFetchingSetRefreshToken,
			error: refreshError,
		},
	] = authApi.useRefreshTokenMutation();

	useEffect(() => {
		const closeAll = (e) => {
			if (e.keyCode === 27) {
				dispatch(changeStateOpenPopup(false));
				dispatch(changeStateOpenAllFiltersPopup(false));
				dispatch(changeStateOpenPopupAdd(false));
				dispatch(changeStateOpenPopupActions(""));
				dispatch(changeStateOpenPopupChangeName(false));
				dispatch(changeStateOpenPopupCreateCard(false));
				dispatch(changeStateOpenPopupChangePassword(false));
				dispatch(changeStateOpenPopupChangeNameCategory(false));
				dispatch(changeStateOpenPopupPostSuccess(false));
				dispatch(changeStateOpenPopupReturn(false));
				dispatch(changeStateOpenPopupReturnRequest(false));
			}
		};
		window.addEventListener("keydown", closeAll);
		return () => window.removeEventListener("keydown", closeAll);
	}, []);

	useEffect(() => {
		dispatch(changeStateAuthorized(false));
		navigate("/auth");
		if (authError?.status === 401 && localStorage.getItem("refresh")) {
			refreshToken();
		}
	}, [authError]);

	useEffect(() => {
		return () => {
			if (refreshError?.status === 401) {
				dispatch(changeStateAuthorized(false));
			}
		};
	}, [refreshError]);

	useEffect(() => {
		if (newToken) {
			localStorage.setItem("access", newToken.access);
			getUserData();
			dispatch(changeStateAuthorized(true));
			navigate("/main");
		}
	}, [newToken]);

	useEffect(() => {
		newPageSize();
		if (localStorage.getItem("access")) {
			setToken();
			dispatch(changeStateAuthorized(true));
			getUserData();
			navigate("/main");
		} else {
			dispatch(changeStateAuthorized(false));
			navigate("/auth");
		}
	}, []);

	async function getUserData() {
		try {
			await fetch("https://photobaza.com/api/v1/users/me", {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access")}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					dispatch(changeUserData(data));
				})
				.catch((error) => console.log(error));
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<div className="app">
			<Header />

			<Routes>
				<Route
					exact
					path="/"
					element={
						authorized ? (
							<Navigate to="/main" />
						) : (
							<Navigate to="/auth" />
						)
					}
				/>
				<Route path="/account/" element={<Account />} />
				<Route path="/main/" element={<Main />} />
				<Route
					path="/auth"
					element={
						authStatus === "login" ? (
							<Auth />
						) : authStatus === "forgot" ? (
							<Forgot />
						) : authStatus === "confirm" ? (
							<Confirm />
						) : authStatus === "new" ? (
							<New />
						) : (
							<Done />
						)
					}
				/>
				<Route path="/*" element={<NotFound />} />
			</Routes>
			{cardInfo && <Popup />}
			<ChangeName />
			<ChangeCategory />
			<ChangePassword />
			<CreateCardPopup />
			<AddCategory />
			<AllFilters />
			<Preloader />
			<ApprovePopup />
			<Footer />
		</div>
	);
}

export default App;
